<template>
  <div id="personal-information">
    <v-card id="v-card" v-if="StartupLoadingFlag">
      <v-container>
        <v-row wrap>
          <v-col cols="12" xl="4" md="6" sm="6" v-for="n in 4" :key="n">
            <br />
            <br />
            <v-skeleton-loader
              v-bind="attrs"
              type="paragraph"
            ></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

    <v-card
      elevation="4"
      outlined
      shaped
      v-if="!StartupLoadingFlag"
      id="v-card"
    >
      <v-card-text id="card-text">
        <v-row wrap>
          <v-col cols="12" sm="12" md="12" lg="3">
            <h3 class="text-dark">
              <i id="app-bar-icon" class="fas fa-user"></i>&nbsp;&nbsp;
              <b id="heading">Personal Information</b>
            </h3>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="9" class="text-center">
            <p v-if="rows.MemberActivityListFlag">
              <v-icon dark> mdi-crown </v-icon> {{ rows.TrainerPoints }} Points
            </p>
            <v-btn
              v-if="rows.ProfileUpdated == 0"
              class="mx-1 mt-2"
              color="red"
              small
              @click.prevent="openUpdateProfile"
            >
              <v-icon dark color="white" small>
                fas fa-exclamation-circle
              </v-icon>
              <span style="color: white; font-size: 10px">
                &nbsp; Update Profile</span
              >
            </v-btn>
            <v-btn
              v-if="!rows.MemberQrCodeFlag"
              class="mx-1 mt-2"
              color="#EC407A"
              small
              @click.prevent="generateQrCodeDetails"
            >
              <v-icon dark color="white" small> fa-solid fa-qrcode </v-icon>
              <span style="color: white; font-size: 10px">
                &nbsp; Generate Qr-Code
              </span>
            </v-btn>
            <router-link
              to="/my-activity/list"
              v-if="rows.MemberActivityListFlag"
            >
              <v-btn class="mx-1 mt-2" color="green" small>
                <v-icon dark color="white" small>
                  fa-solid fa-user-clock
                </v-icon>
                <span style="color: white; font-size: 10px"
                  >&nbsp; Trainer Activities</span
                >
              </v-btn>
            </router-link>

            <v-btn
              class="text-uppercase mx-1 mt-2"
              id="edit-button"
              small
              @click.prevent="openRecordPrompt"
            >
              Edit
            </v-btn>
            <v-btn
              class="text-uppercase mx-1 mt-2"
              id="edit-button"
              small
              @click.prevent="openRecordPrompt1"
            >
              Change Password
            </v-btn>
            <v-btn
              v-if="rows.IdCardFlag != ''"
              :href="rows.IdCardPath"
              target="_blank"
              class="text-uppercase mx-1 mt-2"
              id="edit-button"
              small
            >
              Member Id Card
            </v-btn>
            <v-btn
              v-if="rows.MemberCertificateFlag != ''"
              :href="rows.MemberCertificatePath"
              target="_blank"
              class="text-uppercase mx-1 mt-2"
              id="edit-button"
              small
            >
              Member Certificate
            </v-btn>
            <!-- <p
              class="btn btn-sm text-uppercase"
              id="edit-button"
              @click.prevent="openRecordPrompt"
            >
              Edit
            </p>
            <p
              class="btn btn-sm text-uppercase"
              id="edit-button"
              @click.prevent="openRecordPrompt1"
            >
              Change Password
            </p> -->
          </v-col>
        </v-row>
        <v-row wrap>
          <v-col cols="12" sm="12" md="12" lg="3" xl="3">
            <div class="d-flex justify-content-center">
              <v-img
                :src="rows.MemberImagePath"
                :alt="rows.MemberName"
                lazy-src="/no-image-available.png"
                max-width="250px"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
              <input
                type="hidden"
                ref="ProfilePicture"
                name="profile_avatar"
                accept=".png, .jpg, .jpeg"
                @change="onFileChange($event)"
              />
            </div>
            <div class="text-center">
              <v-btn fab dark small color="indigo" @click.prevent="addRecord">
                <v-icon>mdi-upload</v-icon>
              </v-btn>
            </div>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="6" xl="6" id="card-text">
            <br />
            <div class="d-flex">
              <a
                href="#"
                class="text-dark-75 font-size-h6 font-weight-bold mr-3"
              >
                {{ rows.MemberName }} &nbsp;
                <i class="flaticon2-correct text-success font-size-h6"></i>
              </a>
            </div>
            <div class="d-flex flex-wrap mb-4">
              <span class="text-dark-65 font-size-h6 font-weight-bold mr-3">
                <!-- {{ rows.MembershipId }} -->
              </span>
            </div>
            <div class="d-flex flex-wrap mb-4">
              <!-- <a
                href="#"
                class="text-dark-50 text-hover-primary font-weight-bold mr-lg-8 mr-4 mb-lg-0 mb-2"
              ></a> -->
              <p
                class="text-dark-65 font-size-h6 font-weight-bold mr-lg-8 mr-4 mb-lg-0 mb-2"
              >
                <i class="flaticon2-calendar-3 mr-1"></i>
                {{ rows.MobileNo }} &nbsp;
                <i class="font-size-h6" v-bind:class="rows.MobileNoIcon"></i>
              </p>
              <p
                class="text-dark-65 font-size-h6 font-weight-bold mr-lg-8 mr-4 mb-lg-0 mb-2"
              >
                <i class="flaticon2-new-email mr-1"></i>
                {{ rows.EmailId }} &nbsp;
                <i class="font-size-h6" v-bind:class="rows.EmailIdIcon"></i>
              </p>
            </div>
            <v-row wrap>
              <v-col cols="12" sm="4" md="6" lg="3" xl="3">
                <h6 class="text-dark-50">Membership Id</h6>
                <h6>{{ rows.MembershipId }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="3" xl="3">
                <h6 class="text-dark-50">Date Of Birth</h6>
                <h6>{{ rows.DOB }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="2" xl="3">
                <h6 class="text-dark-50">Gender</h6>
                <h6>{{ rows.GenderTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="4" xl="3">
                <h6 class="text-dark-50">Martial Status</h6>
                <h6>{{ rows.MartialStatusTxt }}</h6>
              </v-col>
              <v-col
                cols="12"
                sm="4"
                md="6"
                lg="4"
                xl="3"
                v-if="rows.MartialStatus == 1"
              >
                <h6 class="text-dark-50">Anniversary Date</h6>
                <h6>{{ rows.AnniversaryDate }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="3" xl="3">
                <h6 class="text-dark-50">Blood Group</h6>
                <h6>{{ rows.BloodGroupTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="4" xl="4">
                <h6 class="text-dark-50">
                  Are you interested to donate blood?
                </h6>
                <h6>
                  {{ rows.DonateBloodTxt }}
                </h6>
              </v-col>
              <v-col cols="12" sm="4" md="6" lg="12" xl="4">
                <h6 class="text-dark-50">About Me</h6>
                <h6>
                  {{ rows.AboutMe }}
                </h6>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="3" xl="3">
            <div class="d-flex justify-content-center mt-6">
              <v-img
                v-if="rows.MemberQrCodeFlag"
                :src="rows.MemberQrImagePath"
                :alt="rows.MemberName"
                lazy-src="/no-image-available.png"
                max-width="250px"
              >
              </v-img>
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-dialog v-model="addRecordPrompt2" persistent max-width="80%">
      <upload-profile-picture
        pageTitle="Change Profile Picture"
        pageDescription=""
        :addRecordPrompt="addRecordPrompt2"
        :currentPhoto="current_photo"
        @hideAddRecordPrompt="changeProfilePhoto"
        v-if="addRecordPrompt2"
      ></upload-profile-picture>
    </v-dialog>

    <v-dialog v-model="addRecordPrompt" persistent max-width="80%">
      <KTPersonalInformation
        v-if="UserFlag && addRecordPrompt"
        :member="rows"
        @hideAddRecordPrompt="hideAddRecordPrompt"
      ></KTPersonalInformation>
    </v-dialog>

    <v-dialog v-model="addRecordPrompt1" persistent max-width="80%">
      <KTChangePassword
        v-if="UserFlag && addRecordPrompt1"
        :member="rows"
        @hideAddRecordPrompt="hideAddRecordPrompt1"
      ></KTChangePassword>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";
import companyConfig from "@/company_config.json";
import common from "@/view/Common.vue";

import UploadProfilePicture from "@/view/pages/profile/my-profile/UploadProfilePicture2.vue";
import KTPersonalInformation from "@/view/pages/profile/my-profile/PersonalInformation";
import KTChangePassword from "@/view/pages/profile/my-profile/ChangePassword";

import {
  ADD_BODY_CLASSNAME,
  REMOVE_BODY_CLASSNAME,
} from "@/core/services/store/htmlclass.module.js";

export default {
  mixins: [common],
  components: {
    UploadProfilePicture,
    KTPersonalInformation,
    KTChangePassword,
  },

  data() {
    return {
      StartupLoadingFlag: false,
      MemberId: 0,
      rows: {},

      default_photo: "media/users/blank.png",
      current_photo: null,

      UserFlag: false,
      addRecordPrompt: false,
      addRecordPrompt1: false,
      addRecordPrompt2: false,
    };
  },
  computed: {},
  watch: {
    // rows: function () {
    //   console.log("watch rows");
    //   this.current_photo = this.rows.MemberImagePath;
    //   this.default_photo = this.rows.DefaultPhoto;
    // },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.getTableRecords();
    },
    onFileChange(e) {
      const file = e.target.files[0];

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.current_photo = event.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    addRecord() {
      console.log("addRecord called");
      this.addRecordPrompt2 = true;
    },
    changeProfilePhoto(photo) {
      console.log("changeProfilePhoto called");
      this.addRecordPrompt2 = false;
      console.log("photo=" + JSON.stringify(photo));
      if (photo != "") {
        this.rows.MemberImagePath = photo;
      }
    },
    openUpdateProfile() {
      console.log("openUpdateProfile called");
      this.$router.push({ path: "/quick-profile-update" });
    },
    getTableRecords() {
      console.log("getTableRecords called");
      this.StartupLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/members/show";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      // this.$store.dispatch(ADD_BODY_CLASSNAME, "page-loading");

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          // thisIns.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");

          output = response.data.output;
          flag = response.data.flag;
          console.log("output=" + output + ", flag=" + flag);

          records = response.data.records[0];
          console.log({ records });

          if (flag == 1) {
            thisIns.rows = records;
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          // this.$store.dispatch(REMOVE_BODY_CLASSNAME, "page-loading");
          // this.toast("error", output);
          this.StartupLoadingFlag = false;
        });
    },
    generateQrCodeDetails() {
      console.log("generateQrCodeDetails called");
      this.StartupLoadingFlag = true;

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/member-qr-code";
      var upload = {
        UserInterface: 1,
        MemberId: this.MemberId,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      const thisIns = this;
      var output = "";
      var records = "";
      var successTxt = "";
      var errorTxt = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));
          thisIns.StartupLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log("output=" + output + ", flag=" + flag);
          successTxt = response.data.success;
          errorTxt = response.data.error;
          console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

          if (flag == 1) {
            thisIns.getTableRecords();
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          // this.toast("error", output);
          this.StartupLoadingFlag = false;
        });
    },
    openRecordPrompt() {
      console.log("openRecordPrompt called");
      this.addRecordPrompt = true;
    },
    hideAddRecordPrompt(flag) {
      console.log("hideAddRecordPrompt called");
      this.addRecordPrompt = false;
      console.log(flag);
      if (flag) {
        this.getTableRecords();
      }
    },
    openRecordPrompt1() {
      console.log("openRecordPrompt1 called");
      this.addRecordPrompt1 = true;
    },
    hideAddRecordPrompt1() {
      console.log("hideAddRecordPrompt1 called");
      this.addRecordPrompt1 = false;
    },
  },
  mounted() {},
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    photo() {
      return this.current_photo == null
        ? this.default_photo
        : this.current_photo;
    },
  },
  beforeMount() {
    console.log("beforeMount called");
    console.log(this.$route.query.id);
    // this.MemberId = this.$route.query.id;
    var MemberId = this.$session.get("MemberId");
    MemberId = MemberId == (null || undefined) ? 0 : MemberId;
    console.log("my-profile beforeMount called");

    var token = this.$session.get("token");
    token = token == (null || undefined) ? 0 : token;

    if (token == 0) {
      this.$router.push("/login");
    } else {
      this.UserFlag = true;
      this.MemberId = MemberId;
      this.refreshPageData();
    }
  },
};
</script>
